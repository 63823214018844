<template>
  <div>
    <div>
      <a-row
        :gutter="24"
        style="padding: 0px 10px;"
      >
        <a-col class="iotplt-card-box">
          <span v-if="!loading">{{ subTitle }}</span>
        </a-col>
      </a-row>
    </div>

    <div>
      <a-row :gutter="24" style="padding-top: 15px">
        <a-col :span="8">
          <a-descriptions
            class="iotplt-card-box"
            title="工商信息"
            layout="vertical"
            :column="1"
            style="height: 600px"
          >
            <a-descriptions-item label="企业全称">
              {{ enterpriseQualification.name }}
            </a-descriptions-item>
            <a-descriptions-item label="工商营业执照注册号">
              {{ enterpriseQualification.registration_no }}
            </a-descriptions-item>
            <a-descriptions-item label="企业工商营业执照">
              <img
                v-if="enterpriseQualification.business_license_url"
                class="img iotplt-cursor-pointer"
                :src="enterpriseQualification.business_license_url"
                alt=""
                @click="handlePreview(enterpriseQualification.business_license_url)"
              >
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="8">
          <a-descriptions
            class="iotplt-card-box"
            title="法人信息"
            layout="vertical"
            :column="1"
            style="height: 600px"
          >
            <a-descriptions-item label="法人姓名">
              {{ enterpriseQualification.legal_name }}
            </a-descriptions-item>
            <a-descriptions-item label="法人身份证号">
              {{ enterpriseQualification.identity_card }}
            </a-descriptions-item>
            <a-descriptions-item label="法人身份证照片（正面）">
              <img
                v-if="enterpriseQualification.front_identity_card_url"
                class="img iotplt-cursor-pointer"
                :src="enterpriseQualification.front_identity_card_url"
                alt=""
                @click="handlePreview(enterpriseQualification.front_identity_card_url)"
              >
            </a-descriptions-item>
            <a-descriptions-item label="法人身份证照片（反面）">
              <img
                v-if="enterpriseQualification.back_identity_card_url"
                class="img iotplt-cursor-pointer"
                :src="enterpriseQualification.back_identity_card_url"
                alt=""
                @click="handlePreview(enterpriseQualification.back_identity_card_url)"
              >
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="8">
          <a-descriptions
            class="iotplt-card-box"
            title="设备信息"
            layout="vertical"
            :column="1"
            style="height: 600px;"
          >
            <a-descriptions-item label="终端设备名称">
              {{ enterpriseQualification.device_name }}
            </a-descriptions-item>
            <a-descriptions-item label="终端设备照片">
              <img
                class="img iotplt-cursor-pointer"
                v-for="(device_url,i) in enterpriseQualification.devices_urls"
                :key="i"
                :src="device_url"
                alt=""
                @click="handlePreview(device_url)"
              >
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>

      <preview-image
        :visible.sync="previewVisible"
        :image.sync="previewImage"
      />
    </div>
  </div>
</template>

<script>
import { FindAgentSale } from '@/api/agent'
import { FindEnterpriseQualification } from '@/api/enterprise_qualification'

import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'EnterpriseQualification',
  components: { PreviewImage },
  data() {
    return {
      loading: true,
      agent_id: this.$store.getters.userAgentId,
      subTitle: '',
      sale: {},
      enterpriseQualification: {},
      previewVisible: false,
      previewImage: ''
    }
  },
  created() {
    this.findAgentSale()
    this.fetchData()
  },
  methods: {
    fetchData() {
      FindEnterpriseQualification({ agent_id: this.agent_id }).then((res) => {
        if (res.code === 0) {
          this.enterpriseQualification = res.data
          if (this.enterpriseQualification.id === 0) {
            this.subTitle = `根据工信部实名制规定，为了保障您的物联网卡使用权益，您需要提交企业资质，请联系工作人员 ${this.sale.full_name}(${this.sale.phone_number})`
          } else {
            this.subTitle = `您已完成企业资质认证，如若修改，请联系工作人员 ${this.sale.full_name}(${this.sale.phone_number})`
          }
        }
      })
    },

    // 查找客户的责任销售
    findAgentSale() {
      this.loading = true
      FindAgentSale(this.agent_id).then((res) => {
        if (res.code === 0) {
          this.sale = res.data
          this.loading = false
        }
      })
    },

    // 显示图片预览
    handlePreview(imageURL) {
      this.previewVisible = true
      this.previewImage = imageURL
    }
  }
}
</script>
<style scoped lang="less">
.img {
  float: left;
  margin: 10px;
  width: 100px;
  height: 100px;
  max-width: 100%;
  min-width: 100px;
  object-fit: cover;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
</style>
